/** @format */

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
}
.font-head {
  font-family: "Poppins", sans-serif !important;
}
.font-para {
  font-family: "Nunito Sans", sans-serif !important;
}
::-webkit-scrollbar-track {
  background: #ffffff !important;
}
::-webkit-scrollbar-thumb {
  background: #1568ff !important;
  border-radius: 16px;
}
::-webkit-scrollbar {
  width: 5px;
}
a {
  text-decoration: none !important;
}
body {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  overflow-x: hidden !important;
  position: relative !important;
}
.navbar {
  position: fixed;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9 !important;
  .nav-link {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #272a2d;
    &:hover {
      color: #1568ff;
    }
  }
}
.nav-btns {
  padding: 18px 32px;
  border-radius: 120px;
  background-color: #1568ff;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-family: Poppins, sans-serif;
  font-size: 0.73em;
  line-height: 1.04em;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: white;
}
.navbar-fixed-top {
  animation-duration: 0.8s;
  animation-name: fadeInDown;
  animation-timing-function: ease-in-out;
  -webkit-box-shadow: 0px 7px 15px -12px rgba(35, 35, 35, 0.15);
  -moz-box-shadow: 0px 7px 15px -12px rgba(35, 35, 35, 0.15);
  box-shadow: 0px 7px 15px -12px rgba(35, 35, 35, 0.15);
  left: 0;
  position: fixed !important;
  right: 0;
  top: 0;
  background: #fff !important;
  width: 100%;
  z-index: 999;
}

.navbar-toggler {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding-right: 20px;
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none !important;
}

.navbar-toggler-icon {
  width: 24px !important;
  height: 17px !important;
  background-image: none !important;
  position: relative !important;
  border-bottom: 3px solid #fff !important;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px !important;
  position: absolute !important;
  height: 2px !important;
  background-color: #fff !important;
  top: 0 !important;
  left: 0 !important;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after {
  top: 8px !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.collapse {
  visibility: visible !important;
}
.btn-style {
  margin-right: 16px !important;
  padding: 18px 32px !important;
  border-radius: 120px !important;
  background: #458dfc !important;
  display: inline-block !important;
  -webkit-transition: all 350ms ease !important;
  transition: all 350ms ease !important;
  font-family: Poppins, sans-serif !important;
  font-size: 0.73em !important;
  line-height: 1.04em !important;
  letter-spacing: 0.25em !important;
  text-transform: uppercase !important;
  color: white !important;
}
.App {
  position: relative;
  z-index: 1;
  width: 100%;
  background-image: url(./bg/bg2x.png);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.App:before {
  position: absolute;
  content: "";
  height: 380px;
  width: 275px;
  top: 0;
  left: 0;
  background-size: 100% 100%;
  background-image: url(./bg/hero-shape-left.png);
  background-repeat: no-repeat;
  z-index: -1;
}
.App:after {
  position: absolute;
  content: "";
  height: 445px;
  width: 620px;
  top: 0;
  right: -20px;
  background-size: 100% 100%;
  background-image: url(./bg/hero-shape-right.png);
  background-repeat: no-repeat;
  z-index: -2;
}
.section-heading {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  h2 {
    position: absolute;
    top: 160px;
  }
  .accent-large {
    height: auto;
    font-family: "Poppins", sans-serif;
    color: #ecf1ff;
    font-size: 13.02em;

    font-weight: 600;
    text-align: center;
  }
}
.sec-title {
  font-family: "Poppins", sans-serif;
  color: #3d006f;
  font-size: 3.39em;
  line-height: 110%;
  font-weight: 400;
  text-align: center;
}
.footer {
  // background: #09142e !important;
  height: 36em !important;
  background-image: url(./bg/white-copy2x.png), url(./bg/bg2x_1.png);
  background-position: 0px 0px, 0px 0px !important;
  background-size: contain, auto !important;
  background-repeat: repeat-y, repeat !important;
  svg {
    margin-top: 20px;
    color: #a5abb3;
    width: 40px;
    height: 20px;
  }
  hr {
    border: 1px solid #a5abb3;
  }
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #858c94;
  }
}
.main_card {
  background-color: rgb(255, 255, 255);
  // box-shadow: rgba(61, 0, 111, 0.1) 1px 1px 59px 0px;
  background-image: url(../public//assets/logo.684b6a10.png);
  color: rgb(0, 0, 0);
  flex: 0 0 340px;
  width: 340px;
  border: 0;
  box-shadow: 1px 1px 59px 0 rgba(61, 0, 111, 0.1) !important;
  border-radius: 20px;
  background-size: auto 180px;
  background-repeat: no-repeat;
  background-position: center;
  margin: 10px;
  padding: 32px 22px 10px;
  // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
@media only screen and (max-width: 600px) {
  .accent-large {
    font-size: 4.02em !important;
    line-height: 75px !important;
  }
  .sec-title {
    font-size: 2.19em;
    // line-height: 10% !important;
  }
  .section-heading h2 {
    position: absolute;
    top: 81px;
  }
  .navbar {
    position: relative;
  }
}
